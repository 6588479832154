import { Chip } from "@mui/material"
import { statusEnum, StatusType } from "../../../../process/types/DataTypes"

export const StatusDisplay = ({ status }: { status: StatusType }) => {
    const { color, name, icon } = statusEnum[status]
    return <Chip label={name} color={color} size="small" icon={icon}/>
}

export const SecondsToStopWatch = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const sec = Math.floor(seconds % 60)
    //const milisec = Math.floor((seconds % 1) * 1000)

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
}

export const dateFormat = (date: string) => {
    return new Date(date).toLocaleString('es-CL', { hour12: false })
}

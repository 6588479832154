import { Box, Container, Typography, Button, Card } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVerifyUserQuery } from '../../app/api/publicApi';

const VerifiedRender = ({token}: {token: string}) => {
    const navigate = useNavigate();
    const { data, isFetching, error } = useVerifyUserQuery({ token: token });

    const getErrorMsg = (error: any) => {
        return error?.data?.detail || 'Error al verificar email';
    }

    return <>
        { isFetching
            ? <Box id="header" bgcolor="primary.main" paddingTop="20px" paddingBottom="20px" borderRadius="10px" textAlign="center">
                    <Typography variant="h4" sx={{ bgcolor: 'primary.main', color: '#ffffff'}}>
                        Verificando email...
                    </Typography>
                </Box>
            : data?.display_name
                ? <Card>
                        <Box id="header" bgcolor="primary.main" paddingTop="20px" paddingBottom="20px" borderRadius="10px" textAlign="center" marginBottom="10px">
                            <Typography variant="h4" sx={{ bgcolor: 'primary.main', color: '#ffffff'}}>
                                Tu email ha sido verificado!
                            </Typography>
                        </Box>
                        <Box id="message" bgcolor="#ffffff" sx={{marginBottom: "20px"}}>
                            <Typography variant="h6" gutterBottom>
                                Bienvenido {data.display_name}, ya puedes ingresar a tu cuenta y comenzar a usar la aplicación de Rovisen.
                            </Typography>
                            <Button
                                onClick={() => navigate('/login', { replace: true }) }
                                variant="contained"
                                color="primary"
                                style={{ color: '#ffffff', padding: '12px 40px', borderRadius: '6px' }}
                                >
                                Ingresar
                            </Button>
                        </Box>
                    </Card>
                : error
                    ? <Box id="header" bgcolor="primary.main" paddingTop="20px" paddingBottom="20px" borderRadius="10px" textAlign="center">
                        <Typography variant="h4" sx={{ bgcolor: 'primary.main', color: '#ffffff'}}>
                            {getErrorMsg(error)}
                        </Typography>
                    </Box>
                    : <Box id="header" bgcolor="primary.main" paddingTop="20px" paddingBottom="20px" borderRadius="10px" textAlign="center">
                        <Typography variant="h4" sx={{ bgcolor: 'primary.main', color: '#ffffff'}}>
                            Error al verificar email
                        </Typography>
                    </Box>
        }
    </>
}

const Verified = () => {
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');

    return (
        <Container maxWidth="sm" sx={{padding: "20px"}}>
            <Box textAlign={'center'}>
                { token
                    ? <VerifiedRender token={token} />
                    : <Box id="header" bgcolor="primary.main" paddingTop="20px" paddingBottom="20px" borderRadius="10px" textAlign="center">
                        <Typography variant="h4" sx={{ bgcolor: 'primary.main', color: '#ffffff'}}>
                            Error al verificar email
                        </Typography>
                    </Box>
                }
            </Box>
        </Container>
    );
};

export default Verified;

import { Cancel, Done, Error, Refresh, Schedule } from "@mui/icons-material"
import { ManipulateType } from "dayjs"


export type StatusType = "queued" | "running" | "finished" | "canceled" | "failed"
export type ColorType = "primary" | "secondary" | "info" | "success" | "warning" | "error"

export const statusEnum: { [key in StatusType]: { color: ColorType, name: string, icon?: any } } = {
    "queued": { color: "secondary", name: "Procesando", icon: <Schedule />},
    "running": { color: "info", name: "Ejecutando", icon: <Refresh /> },
    "finished": { color: "success", name: "Completado", icon: <Done /> },
    "canceled": { color: "warning", name: "Cancelado", icon: <Cancel /> },
    "failed": { color: "error", name: "Error", icon: <Error /> },
}


export interface PipelineInstance {
    id: string
    name: string
    execution_arn: string
    pipeline_model_id: string
    input_data: any
    output_data: any
    status: StatusType
    runtime: number
    started_at: string
    finished_at: string
    user_id: string
    organization_id: string
    created_at: string
    cost_estimated: number
    cost: number
}

export interface Raster {
    id: string,
    name: string
    origin: string
    object_key: string
    bucket: string
    url?: string
    resolution?: number
    channels?: number
    dtype?: string
    preview?: string
    preview_bbox: string
    user_id: string
    organization_id: string
    created_at: string
}

export interface Geometry {
    id: string
    type: string
    geom?: string
    object_key: string
    origin: string
    preview?: string
    preview_bbox?: string
    user_id: string
    organization_id: string
    name?: string
    bucket?: string
    created_at: string
}

export interface SentinelProduct {
    cloud: number
    date: string
    footprint: string
    on_db: boolean
    online: boolean
    requested: boolean
    tile: string
    title: string
    uuid: string
    quicklook: string
}

export interface PipelineModel {
    id: string
    name: string
    title: string
    subtitle: string
    description: string
    img_url: string
    input_format: any
    output_format: any
    user_id: string
    organization_id: string
    created_at: string
    config?: any
    price: number
    is_active: boolean
}

export interface Mosaics {
    id: 1
    name: string
    description: string
    created_at: string
    coverage_store: string
    workspace: string
}

export interface ConfigInput {
    type: "date" | "geom" | "date_range" | "number" | "boolean" | "string" | "raster" | "sentinel" | "list"
    name: string
    description?: string
    subType?: "date" | "geom" | "date_range" | "number" | "boolean" | "string" | "raster" | "sentinel"
    required?: boolean
}

export interface ConfigDateInput extends ConfigInput {
    default?: string
    maxDate?: string
    minDate?: string
}

interface DateRange {
    unit: ManipulateType
    value: number
}

export interface ConfigDateRangeInput extends ConfigInput {
    initDate: ConfigDateInput
    endDate: ConfigDateInput
    maxDate?: string
    minDate?: string
    maxRange?: DateRange
    minRange?: DateRange
}

export interface ConfigNumberInput extends ConfigInput {
    default?: number
    minValue?: number
    maxValue?: number
}

export interface ConfigBooleanInput extends ConfigInput {
    default?: boolean
}

export interface ConfigGeometryInput extends ConfigInput {
    geomType: "Polygon" | "Point" | "LineString"
    processBbox?: boolean
}

export interface ConfigRasterInput extends ConfigInput {
    processBbox?: boolean
    maxRes?: number,
    minRes?: number,
    maxChannels?: number,
    minChannels?: number,
    dtypes?: string[],
}

export interface ConfigSentinelInput extends ConfigInput {
}

export interface ConfigStringInput extends ConfigInput {
    maxLength?: number
    default?: string
}

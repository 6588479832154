import { IconButton, Tooltip } from "@mui/material";

const ToggleButton = (
    {
        selected,
        tooltip,
        selectedTooltip,
        icon,
        selectedIcon,
        onClick,
        enabled = true,
        color = 'default'
    }: {
        selected: boolean,
        tooltip: string,
        selectedTooltip?: string,
        icon: any,
        selectedIcon?: any,
        onClick?: () => void,
        enabled?: boolean,
        color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    }) => {
    return (
        <Tooltip title={selected ? selectedTooltip ? selectedTooltip : tooltip : tooltip}>
            <span>
                <IconButton
                    disabled={!enabled}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation()
                        onClick && onClick()
                    }}
                    size='large'
                    aria-describedby={tooltip + '-toggle-button'}
                    color={color}
                >
                    {selected ? selectedIcon ? selectedIcon : icon : icon}
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default ToggleButton;

import { Box, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import RovisenMapControl from "../control/RovisenMapControl";
import { useMap, useMapEvents } from "react-leaflet";
import { Children, ReactElement, cloneElement, useState } from "react";
import { Close, Menu } from "@mui/icons-material";

enum ResposiveClasses {
    XL,
    L,
    MD,
    S,
    XS,
}

const TabButton = ({ onClick, icon, selected = false, borderRadius = '0px' }: { onClick: any, icon: any, selected?: boolean, borderRadius?: string }) => {
    const theme = useTheme()
    const backgroundColor = selected ? theme.palette.primary.main : theme.palette.background.paper
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 1,
                borderRadius: { borderRadius },
                backgroundColor: backgroundColor,
                '&:hover': { backgroundColor: selected ? backgroundColor : '#dedede' }
            }}
            onClick={onClick}
        >
            {icon}
        </Box>
    )
}

const getResposiveWidth = (width: number): string => {
    switch (getResposiveClass(width)) {
        case ResposiveClasses.XL:
        case ResposiveClasses.L:
            return "600px"
        case ResposiveClasses.MD:
            return "500px"
        case ResposiveClasses.S:
            return (width - 50).toString() + 'px'
        case ResposiveClasses.XS:
            return (width - 50).toString() + 'px'

    }
}


const getResposiveClass = (width: number): ResposiveClasses => {
    if (width > 1500) return ResposiveClasses.XL
    if (width > 1200) return ResposiveClasses.L
    if (width > 900) return ResposiveClasses.MD
    if (width > 600) return ResposiveClasses.S
    return ResposiveClasses.XS
}

interface DrawerTabProps {
    icon: any
    title: string
    children: any
}

export const DrawerTab = ({ icon, title, children, }: DrawerTabProps) => null

interface BaseMapDrawerProps {
    collapsed?: boolean
    tabIndex?: number
    children: ReactElement<DrawerTabProps>[] | ReactElement<DrawerTabProps>
}


const BaseMapDrawer = ({ collapsed, tabIndex = 0, children }: BaseMapDrawerProps) => {

    const theme = useTheme()
    const map = useMap()
    const { x, y } = map.getSize()

    const [responsiveClass, setResponsiveClass] = useState(getResposiveClass(x))

    const [width, setWidth] = useState(getResposiveWidth(x))
    const [height, setHeight] = useState(y - 50)

    const [internalCollapsed, setCollapsed] = useState(collapsed === undefined ? responsiveClass >= ResposiveClasses.MD : collapsed)

    useMapEvents({
        resize: () => {
            const { x, y } = map.getSize()
            const newClass = getResposiveClass(x)
            setResponsiveClass(newClass)
            setWidth(getResposiveWidth(x))
            setHeight(y - 50)
            setCollapsed(newClass > ResposiveClasses.MD)
            setHideCollapsed(newClass > ResposiveClasses.MD)
        }
    })

    const [selectedTab, setSelectedTab] = useState(tabIndex)
    const tabs: ReactElement<DrawerTabProps>[] = []
    Children.forEach(children, child => {
        tabs.push(child)
    })

    const [hideCollapsed, setHideCollapsed] = useState(internalCollapsed)

    return (
        <RovisenMapControl position='topleft'>
            <Collapse
                orientation="horizontal"
                in={!internalCollapsed}
                collapsedSize={40}
                onExited={() => {
                    setHideCollapsed(true)
                    setSelectedTab(0)
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: "row", width: internalCollapsed ? 'auto' : width, height: height, backgroundColor: theme.palette.background.paper, borderRadius: 3, opacity: 0.9, maxWidth: width }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {Children.map(children, (tab, index) => (
                            <TabButton
                                borderRadius={index === 0 ? internalCollapsed ? '10px 10px 0px 0px' : '10px 0px 0px 0px' : '0px'}
                                icon={index === 0
                                    ? <Menu fontSize="medium" sx={{ color: internalCollapsed ? 'gray' : selectedTab === index ? 'white' : 'gray' }} />
                                    : cloneElement(tab.props.icon, { fontSize: "medium", style: { color: selectedTab === index ? 'white' : 'gray' } })}
                                onClick={() => {
                                    if (internalCollapsed) {
                                        setCollapsed(false)
                                        setHideCollapsed(false)
                                    }
                                    else {
                                        index === 0 && selectedTab === 0 && setCollapsed(true)
                                    }
                                    setSelectedTab(index)

                                }}
                                selected={index === 0 ? !internalCollapsed && index === selectedTab : index === selectedTab} />
                        ))}
                    </Box>
                    <Box sx={{ display: hideCollapsed ? 'none' : 'flex', flexDirection: 'column', flexGrow: 1, overflow: "hidden" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: theme.palette.primary.main, borderRadius: '0px 10px 0px 0px', width: 1 }}>
                            <Typography variant="h5" sx={{ flexGrow: 1, textAlign: "center", color: 'white' }}>{tabs[selectedTab].props.title}</Typography>
                            <IconButton size="medium" onClick={() => {
                                setCollapsed(true)
                            }}>
                                <Close fontSize="inherit" />
                            </IconButton>
                        </Box>
                        {tabs.map((tab, index) => (
                            <Box
                                sx={{ display: index === selectedTab ? "flex" : "none", flexDirection: 'column', overflow: "hidden", flexGrow: 1, width: 1 }}
                                key={index}
                            >
                                {tab.props.children}
                            </Box>))}
                    </Box>
                </Box>
            </Collapse>
        </RovisenMapControl>
    );
}


export default BaseMapDrawer;

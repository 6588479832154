import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { userRoles } from '../authentication/components/userRoles';
import Login from '../authentication/views/Login';
import Register from '../authentication/views/Register';
import DashBoard from '../dashboard/views/DashBoard';
import MainMap from '../mainMap/views/MainMap';
import ProcessRoutes from '../process/routes';
import Risks from '../risksMap/views/Risks';
import RovLabelRoutes from '../rovLabel/routes';
import './App.css';
import PermissionRoutes from './components/PermissionRoutes';
import PrivateRoutes from './components/PrivateRoutes';
import NavBar from './views/layout/NavBar';
import NotFound from './views/NotFound';
import Unauthorized from './views/Unauthorized';
import AuthRoutes from '../authentication/AuthRoutes';
import ProfileRoutes from '../authentication/ProfileRoutes';
import { esES } from '@mui/material/locale';
import { useState } from 'react';
import type {} from '@mui/x-data-grid/themeAugmentation'

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#473066",
    },
    secondary: {
      main: "#00b0ff",
    },
    background: {
      default: "#fff",
      paper: "#fafbfe"
    },
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: '#ECF1F6',
    }
  }
}, esES)


/*const oldTheme = createTheme({
  palette: {
    primary: {
      main: "#473066",
    },
    secondary: {
      main: "#7464a1",
    },
    background: {
      default: "#fcfcfc",
      paper: "#fff"
    },
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: '#f0f0f0',
    }
  }
}, esES)*/

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#c0a5e5",
    },
    secondary: {
      main: "#b4e7ff",
    },
    background: {
      default: '#000',
      paper: '#0B0C0D',
    },
    text: {
      primary: '#ECF1F6'
    }
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: '#121415',
    }
  }
}, esES)

function App() {
  
  const [isDark, setIsDark] = useState(false) 

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme/>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', boxSizing: 'border-box', overflow: "hidden", width: '100vw' }}>
        <BrowserRouter>
          <NavBar isDark={isDark} setIsDark={setIsDark}/>
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Routes>
              <Route element={<PrivateRoutes />} >
                <Route element={<PermissionRoutes allowedRoles={[userRoles.admin]} />}>
                  <Route path="/mainMap" element={<MainMap />} />
                  <Route path="/dashboard-sentinel" element={<DashBoard />} />
                </Route>

                <Route element={<PermissionRoutes allowedRoles={[userRoles.label]} />}>
                  <Route path='/rovlabel/*' element={<RovLabelRoutes />} />
                </Route>

                <Route element={<PermissionRoutes allowedRoles={[userRoles.risk]} />}>
                  <Route path="/risks" element={<Risks />} />
                </Route>

                <Route element={<PermissionRoutes allowedRoles={[userRoles.inference]} />}>
                  <Route path='/*' element={<ProcessRoutes />} />
                </Route>

                <Route path="/profile/*" element={<ProfileRoutes />} />
              </Route>
              <Route path="/auth/*" element={<AuthRoutes />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;

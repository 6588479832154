import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/state/store";
import { FormControlLabel, Radio, RadioGroup, useTheme } from "@mui/material";
import { Map } from "@mui/icons-material";
import { BaseMapOption, setBaseMap, setStreets } from "../../state/baseMapSlice";
import InfoButton from "../../../common/components/UI/general/InfoButton";

const BaseMapSelector = () => {
    const dispatch = useDispatch<AppDispatch>()
    const value = useSelector((state: RootState) => state.baseMap.baseMap)
    const theme = useTheme()

    return (
        <InfoButton title="Base Map" icon={<Map fontSize="inherit" />} placement="top">
            <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={value}
                    name="radio-buttons-group"
                    sx={{ padding: 1, borderRadius: 1, backgroundColor: theme.palette.background.paper, boxShadow: 3 }}
                    onChange={(e, value) => {
                        dispatch(setBaseMap(value as BaseMapOption))
                        dispatch(setStreets(value !== 'osm'))
                    }}
                >
                    <FormControlLabel value="esri" control={<Radio />} label="ESRI" />
                    <FormControlLabel value="osm" control={<Radio />} label="OpenStreetMap" />
                    <FormControlLabel value="rov" control={<Radio />} label="Sentinel 2" />
                    <FormControlLabel value="google" control={<Radio />} label="Google Satellite" />
                </RadioGroup>
        </InfoButton>
    )
}

export default BaseMapSelector;

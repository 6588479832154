import { AlertColor, Box, Icon, Link, Stack, Typography } from "@mui/material";
import { FormContainer, FormHeader, FormTextField } from "./Form";
import { LoadingButton } from "@mui/lab";
import { useForgotPasswordMutation } from "../../app/api/publicApi";
import { useState } from "react";
import { CustomAlertDialog } from "../../common/components/UI/general/AlertDialog";

const ForgotPassword = () => {
    const [forgotPasswordTrigger, result] = useForgotPasswordMutation();
    const { isLoading } = result;

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('error');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email') as string;

        forgotPasswordTrigger({ email })
            .unwrap()
            .then((response) => {
                console.log(response);
                setAlertMessage(response?.message);
                setSeverity('success');
                setAlertOpen(true);
            })
            .catch((error) => {
                console.log(error);
                setAlertMessage(error?.data?.detail || 'Error al enviar el correo');
                setSeverity('error');
                setAlertOpen(true);
            });
    };

    return (
        <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
            <FormContainer>
                <FormHeader title="Recuperar Cuenta" />
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography variant="body2" color="textSecondary" align="left">Ingrese el email asociado a su cuenta</Typography>
                    <FormTextField id="email" label="Email" name="email" type="email" autoComplete="email" checkValidity helperText="Debe ingresar un mail válido" />
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                        loadingPosition="end"
                        endIcon={<Icon />}
                    >
                        Recuperar Cuenta
                    </LoadingButton>
                    <Stack direction='row' justifyContent={'space-between'}>
                        <Link href="/login" variant="body2">
                            Iniciar Sesión
                        </Link>
                        <Link href="/register" variant="body2">
                            Crear Cuenta
                        </Link>
                    </Stack>
                </Box>
                <CustomAlertDialog alertOpen={alertOpen} setAlertOpen={setAlertOpen} message={alertMessage} severity={severity} />
            </FormContainer>
        </Box>
    );
}

export default ForgotPassword;

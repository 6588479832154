import L from "leaflet";
import { useEffect, useRef, useState } from "react"
import { GeoJSON } from "react-leaflet"

export function InteractiveGeoJSON({geojson, setSelectedFeature = undefined, defaultColor = '#3388ff'}: {geojson: any, setSelectedFeature?: any, defaultColor?: string}) {
    const [feature, setFeature] = useState({})
    const polygonRef = useRef<any>()

    const geojsonMarkerOptions = {
        radius: 8,
        fillColor: defaultColor,
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8
    };

    const polygonStyle = {
        color: defaultColor,
        fillColor: defaultColor,
        fillOpacity: 0.8
    }

    useEffect(() => {
        setSelectedFeature && setSelectedFeature(feature)
    }, [feature, setSelectedFeature])

    return <GeoJSON
        data={geojson}
        style={polygonStyle}
        pointToLayer={(feature, latlng) => {
            return L.circleMarker(latlng, geojsonMarkerOptions)
        }}
        onEachFeature={(feature, layer) => {
            layer.on({
                'click': (e) => {
                    setFeature(feature)
                    polygonRef.current && polygonRef.current.setStyle({ color: defaultColor, fillColor: defaultColor })
                    polygonRef.current = e.target
                    e.target.setStyle({ color: 'black', fillColor: 'red' })
                },
                'mouseover': (e) => {
                    e.target !== polygonRef.current &&
                    e.target.setStyle({ color: 'white', fillColor: 'green' })
                },
                'mouseout': (e) => {
                    e.target !== polygonRef.current &&
                    e.target.setStyle({ color: defaultColor, fillColor: defaultColor })
                }
            })
        }}
    />
}

import { Box, Container, Divider, Stack } from "@mui/material"
import { useCallback, useRef, useState } from "react"
import { useGetPipelineInstanceQuery } from "../../api/processApi"
import { useMaskMapDisplay } from "../../hooks/useMask"
import DraggableLayout from "../../../common/components/layout/DraggableLayout"
import { DetectionsDisplay, GeomDisplay, RasterMapDisplay, RasterOpacityDisplay } from "./DataDisplay"
import WktLayer from "../../../common/utils/WktLayer"
import { PipelineModel } from "../../types/DataTypes"
import useGeoJsonResponse from "../../hooks/useGeoJsonResponse"
import { BreadcrumbsRender, ExecutionTitleRender, PropertiesRender, ResultsMap } from "../../views/ResultsViewer"
import { InteractiveGeoJSON } from "../../../common/utils/InteractiveGeoJSON"


const ImpactAssessmentViewer = ({ pipelineInstanceId, model }: { pipelineInstanceId: string, model: PipelineModel }) => {
    const { data: pipelineInstance } = useGetPipelineInstanceQuery(pipelineInstanceId)

    const { geojson: geojson_100, downloadGeoJson: d_geojson_100 } = useGeoJsonResponse(pipelineInstance?.output_data?.intersection_100?.id, {skip: !pipelineInstance?.output_data?.intersection_100?.id})
    const { geojson: geojson_200, downloadGeoJson: d_geojson_200 } = useGeoJsonResponse(pipelineInstance?.output_data?.intersection_200?.id, {skip: !pipelineInstance?.output_data?.intersection_200?.id})
    const { geojson: geojson_500, downloadGeoJson: d_geojson_500 } = useGeoJsonResponse(pipelineInstance?.output_data?.intersection_500?.id, {skip: !pipelineInstance?.output_data?.intersection_500?.id})

    const [feature, setFeature] = useState({})

    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])

    const { mask, showMask, setShowMask } = useMaskMapDisplay(pipelineInstance?.input_data?.mask, map)
    const [showDetections_100, setShowDetections_100] = useState(true)
    const [showDetections_200, setShowDetections_200] = useState(true)
    const [showDetections_500, setShowDetections_500] = useState(true)
    const [opacity, setOpacity] = useState(0.5);
    const [showRasters, setShowRasters] = useState(true);


    return <DraggableLayout
        topLeft={
            <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: "column", overflow: 'auto' }}>
                <Stack direction={'column'} sx={{ paddingX: 5, paddingY: 2 }} spacing={3}>
                    <BreadcrumbsRender model={model} instanceId={pipelineInstanceId} />
                </Stack>
                <Container>
                    <Stack paddingY={2} divider={<Divider variant="middle" flexItem />} spacing={1}>
                        <ExecutionTitleRender pipelineInstance={pipelineInstance} />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <GeomDisplay showMask={showMask} setShowMask={setShowMask} />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <RasterOpacityDisplay title={model.output_format?.historic?.name} showRaster={showRasters} setShowRaster={setShowRasters} opacity={opacity} setOpacity={setOpacity} />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <DetectionsDisplay title={model.output_format?.intersection_100?.name} show={showDetections_100} setShow={setShowDetections_100} onClick={d_geojson_100} />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <DetectionsDisplay title={model.output_format?.intersection_200?.name} show={showDetections_200} setShow={setShowDetections_200} onClick={d_geojson_200} />
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <DetectionsDisplay title={model.output_format?.intersection_500?.name} show={showDetections_500} setShow={setShowDetections_500} onClick={d_geojson_500} />
                        </Stack>
                    </Stack>
                </Container>
                <Divider flexItem />
                <PropertiesRender feature={feature} setFeature={setFeature} />
            </Box>
        }
        downRight={
            <ResultsMap map={map} >
                {showRasters && pipelineInstance?.output_data?.historic?.id
                    && <RasterMapDisplay rasterId={pipelineInstance.output_data.historic.id} opacity={opacity}/>}
                {showDetections_100 && geojson_100 &&
                    <InteractiveGeoJSON geojson={geojson_100} setSelectedFeature={setFeature} defaultColor="red" />
                }
                {showDetections_200 && geojson_200 &&
                    <InteractiveGeoJSON geojson={geojson_200} setSelectedFeature={setFeature} defaultColor="yellow" />
                }
                {showDetections_500 && geojson_500 &&
                    <InteractiveGeoJSON geojson={geojson_500} setSelectedFeature={setFeature} defaultColor="green"/>
                }
                {showMask && mask && <WktLayer wktData={mask} pathOptions={{ color: 'red', fillOpacity: 0 }} />}
            </ResultsMap>

        }
        onDragEnd={updateMapSize}
        onChangeOrientation={updateMapSize}
    />
}

export default ImpactAssessmentViewer

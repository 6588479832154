import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid2, Skeleton, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

export interface CardDetails {
    id: string,
    title: string,
    subtitle: string,
    description: string,
    img_url: string,
    is_active: boolean,
}

interface CardSelectorProps {
    data: CardDetails[]
    value?: CardDetails
    defaultValue?: number
    unavaliables?: CardDetails[]
    isLoading?: boolean
    onChange?: (selected: CardDetails) => void
}

const CardSelector = ({ data, isLoading = true }: CardSelectorProps) => {
    const navigate = useNavigate()
    const theme = useTheme()

    return <Box sx={{ width: 1, height: 1, overflow: 'auto', }}>
        <Container>
            <Box sx={{ marginTop: 4 }}>
                <Grid2 container columns={12}>
                    {isLoading
                        ? [1, 2, 3].map((value) => (
                            <Grid2 size={{sm:12, md:6, lg:4}} key={value}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
                                    <Card
                                        sx={{
                                            width: 345,
                                        }}
                                        key={value}
                                    >
                                        <Skeleton
                                            sx={{ height: 140 }}
                                            variant="rectangular"
                                            animation="wave"
                                        />
                                        <CardContent>
                                            <Skeleton
                                                animation="wave"
                                                height={30}
                                                width="60%"
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton animation="wave" height={20} width="100%" />
                                            <Skeleton animation="wave" height={20} width="100%" />
                                            <Skeleton animation="wave" height={20} width="100%" />
                                        </CardContent>
                                        <CardActions>
                                            <Skeleton animation="wave" height={50} width={150} />
                                            <Skeleton animation="wave" height={50} width={150} />
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Grid2>
                        ))
                        : [...data].sort((a, b) => {
                            if (a.title === b.title)
                                return a.subtitle.localeCompare(b.subtitle)
                            return a.title.localeCompare(b.title)
                        }).map((card, index) => (
                            <Grid2 size={{sm:12, md:6, lg:4}} display={'flex'}>
                                <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
                                    <Card
                                        sx={{
                                            maxWidth: 345,
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                        key={index}
                                    >
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={card.img_url}
                                            title={card.title}
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography variant="h5" component="div">
                                                {card.title}
                                            </Typography>
                                            <Typography gutterBottom variant="h6" color="text.secondary">
                                                {card.subtitle}
                                            </Typography>
                                            <Typography
                                                variant="body2" color="text.secondary">
                                                {card.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {card.is_active
                                                ? <>
                                                    <Button onClick={() => navigate(`${card.id}`)}>Información</Button>
                                                    <Button onClick={() => navigate(`${card.id}/new_execution`)}>Nueva ejecución</Button>
                                                </>
                                                : <Button disabled>En desarrollo</Button>}

                                        </CardActions>
                                    </Card>
                                </Box>
                            </Grid2>
                        ))}
                </Grid2>
            </Box>
        </Container>
    </Box>
}

export default CardSelector
import {
    Box,
    Container,
    TextField,
    Avatar,
    Typography,
    TextFieldProps
} from "@mui/material";
import { useCallback, useState } from "react";


const FormContainer = ({ children }: { children: any }) => (
    <Container component="main" maxWidth="xs">
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {children}
        </Box>
    </Container>
);

export interface FormTextFieldProps extends Omit<TextFieldProps, "margin" | "required" | "fullwidth"> {
    checkValidity?: boolean,
    validityFunction?: (value: string) => boolean,
    setValue?: (value: string) => void,
}

const FormTextField = ({ id, label, name, type = "text", autoComplete, autoFocus = false, checkValidity, validityFunction, helperText, setValue: setExternalValue }: FormTextFieldProps) => {
    const [value, setValue] = useState("")
    const [error, setError] = useState(false)
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(e.target.value)
        setExternalValue && setExternalValue(e.target.value)
        if (checkValidity) {
            if (validityFunction) {
                if (validityFunction(e.target.value))
                    setError(true)
                else
                    setError(false)
            }
            else
                setError(!e.target.validity.valid)
        }
    }, [setValue, checkValidity, validityFunction, setExternalValue])
    return <TextField
        margin="normal"
        required
        fullWidth
        value={value}
        onChange={handleChange}
        id={id}
        label={label}
        name={name}
        autoComplete={autoComplete}
        type={type}
        autoFocus={autoFocus}
        error={error}
        helperText={error ? helperText : ""}
    />
};


const FormHeader = ({ title }: { title: string }) => (
    <>
        <Avatar src="https://s3.us-east-2.amazonaws.com/gis.rovisen.com/logo_120.png" sx={{ m: 1 }} />
        <Typography component="h1" variant="h5">
            {title}
        </Typography>
    </>
);


export { FormContainer, FormTextField, FormHeader };

import { useSelector } from "react-redux"
import { selectPlaceById, selectRiskLayerValues, selectRiskLayersTitles, selectSelectedPlace } from "../../state/riskSlice"
import { RootState } from "../../../app/state/store"
import { Box, Checkbox, CircularProgress, Collapse, Divider, Grid2, Slider, Tooltip, TooltipProps, Typography, Zoom, styled, tooltipClasses } from "@mui/material"
import { BookmarkAdd, BookmarkAddOutlined, BookmarkRemove, BookmarkRemoveOutlined, Download, DownloadOutlined, LocationOnOutlined, LocationSearching, Share, ShareOutlined, Tune } from "@mui/icons-material"
import { useEffect, useState } from "react"
import RiskAnalysis from "./riskAnalysis"
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip as ChartTooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2'
import { useSaveAnalysisMutation } from "../../api/risksApi"
import { LoadingButton } from "@mui/lab"
import { HexColorPicker } from "react-colorful"

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ChartTooltip,
    Legend
);

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'black',
        color: 'white',
        boxShadow: theme.shadows[0],
        fontSize: 15,
    },
}));


const CustomButton = (props: any) => {
    const [hover, setHover] = useState(false)
    return (
        <LightTooltip title={props.tooltip} placement="top" TransitionComponent={Zoom} enterDelay={500}>
            <LoadingButton
                variant="outlined"
                size="large"
                sx={{ paddingX: 6 }}
                loading={props.loading}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                color="primary"
                onClick={props.onClick}
            >
                {hover
                    ? props.hoverIcon
                    : props.icon
                }
            </LoadingButton>
        </LightTooltip>
    )
}

const RadarChart = () => {
    const place = useSelector(selectSelectedPlace)

    const [showConfig, setShowConfig] = useState(false)
    const [color, setColor] = useState("#ff6384")
    const [smoth, setSmoth] = useState(0)

    const data = {
        labels: useSelector((state: RootState) => selectRiskLayersTitles(state, place.layers)),
        datasets: [
            {
                label: "Riesgos",
                data: useSelector((state: RootState) => selectRiskLayerValues(state, place.id)),
                backgroundColor: color + "44",
                borderColor: color,
                borderWidth: 1,
            }
        ],
    }

    return <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Tooltip title="Configuración">
                <Checkbox
                    icon={<Tune />}
                    checkedIcon={<Tune />}
                    checked={showConfig}
                    onChange={(e, value) => setShowConfig(value)}
                />
            </Tooltip>
        </Box>
        <Collapse in={showConfig}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 2, justifyContent: "space-between" }}>
                <Typography sx={{ padding: 2 }}>Color:</Typography>
                <HexColorPicker color={color} onChange={(value) => setColor(value)} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 2, justifyContent: "space-between" }}>
                <Typography sx={{ padding: 2 }}>Smoth:</Typography>
                <Slider value={smoth} onChange={(e, value) => setSmoth(Number(value))} min={0} max={1} step={0.01} valueLabelDisplay="auto" />
            </Box>
        </Collapse>
        <Radar data={data} options={{ elements: { line: { tension: smoth } } }}/>
    </Box>
}

const RiskResult = ({ placeId }: { placeId: string }) => {
    const place = useSelector((state: RootState) => selectPlaceById(state, placeId))

    const [saveAnalysis, saveResults] = useSaveAnalysisMutation()

    useEffect(() => {
        console.log("loading", saveResults.isLoading)
    }, [saveResults.isLoading])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1, overflow: "hidden", flexGrow: 1 }}>
            <Typography variant="h4">{place.name}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <LocationOnOutlined fontSize="medium" color="disabled" sx={{ padding: 1 }} />
                {place.name === ''
                    ? <Typography><span>&#8212;</span></Typography>
                    : <Typography>{place.address}</Typography>
                }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <LocationSearching fontSize="medium" color="disabled" sx={{ padding: 1 }} />
                <Typography>{place.coords[0].toFixed(8)}, {place.coords[1].toFixed(8)}</Typography>
            </Box>
            <Grid2 container spacing={1} sx={{ marginTop: 5 }}>
                <Grid2 size={{ xs: 4 }}>
                    {place.saved
                        ? <CustomButton
                            hoverIcon={<BookmarkRemoveOutlined />}
                            icon={<BookmarkRemove />}
                            tooltip={"Remove current place"}
                            onClick={() => saveAnalysis({ analysisId: place.db_id, saved: false })}
                            loading={saveResults.isLoading}
                        />
                        : <CustomButton
                            hoverIcon={<BookmarkAdd />}
                            icon={<BookmarkAddOutlined />}
                            tooltip={"Save current place"}
                            onClick={() => saveAnalysis({ analysisId: place.db_id, saved: true })}
                            loading={saveResults.isLoading}
                        />
                    }

                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                    <CustomButton hoverIcon={<Share />} icon={<ShareOutlined />} tooltip={"Share"} />
                </Grid2>
                <Grid2 size={{ xs: 4 }}>
                    <CustomButton hoverIcon={<Download />} icon={<DownloadOutlined />} tooltip={"Download report"} />
                </Grid2>
            </Grid2>
            <Divider variant="middle" sx={{ marginY: 2 }} flexItem></Divider>
            {place.layers.length > 0
                ? <Box sx={{ display: 'flex', flexDirection: 'column', flexFlow: 1, overflow: 'auto' }}>
                    <RadarChart />
                    <Divider variant="middle" flexItem></Divider>
                    <RiskAnalysis />
                </Box>
                : <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>}
        </Box>
    )
}

export default RiskResult

import { Cancel, SaveAlt, UploadFile } from "@mui/icons-material";
import { Box, Button, Divider, Grid2, Icon, IconButton, LinearProgress, Paper, Stack, styled, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getGeoTiffData, getTiffCoordinates } from "../../../common/utils/geoTiffUtils";
import { useUploadImageInfoMutation } from "../../../mainMap/api/mainMapApi";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { latLng, LatLngBounds, latLngBounds } from "leaflet";
import { latLngBoundsToWKT } from "../../../common/utils/tools";
import formatBytes from "../../../common/utils/numberToBytes";
import { useLazyGetImageUploadUrlQuery } from "../../api/processApi";
import { CustomAlertDialog, CustomAlertState } from "../../../common/components/UI/general/AlertDialog";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadFileModal = ({ setBbox: setExternalBbox, onClose }: { bbox?: LatLngBounds, setBbox?: (bbbox: LatLngBounds | undefined) => void, onClose?: any }) => {
    const theme = useTheme()
    const [dropping, setDropping] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [validFile, setValidFile] = useState(false)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0);
    const [bbox, setBbox] = useState<LatLngBounds>()
    const [ready, setReady] = useState(false)

    const [{ severity, message, alertOpen }, setAlert] = useState<CustomAlertState>({ severity: 'success', message: '', alertOpen: false })

    const controller = useRef<AbortController | null>(null)

    const [imageUploadUrlTrigger] = useLazyGetImageUploadUrlQuery();
    const [uploadImageInfoTrigger] = useUploadImageInfoMutation();

    const fileUpload = useCallback((tempFile: File | null) => {
        if (tempFile && tempFile.type === "image/tiff") {
            setValidFile(true)
            setFile(tempFile)
            const url = URL.createObjectURL(tempFile)
            getGeoTiffData(url).then(({ bbox, proj4String }) => {
                const coordinate1 = getTiffCoordinates(bbox[1], bbox[0], proj4String.proj4)
                const coordinate2 = getTiffCoordinates(bbox[3], bbox[2], proj4String.proj4)
                setBbox(latLngBounds(latLng(coordinate1.lat, coordinate1.lon), latLng(coordinate2.lat, coordinate2.lon)))
            })
        }
        else {
            setValidFile(false)
            setFile(null)
            setAlert({ severity: 'error', message: 'El archivo no es un GeoTIFF', alertOpen: true })
        }
    }, [setBbox])

    const handleUpload = async () => {
        if (file) {
            if (controller.current === null) {
                controller.current = new AbortController();
            }
            setLoading(true)
            setReady(false)
            const secure_url_response = await imageUploadUrlTrigger().unwrap()
            try {
                const upload_response = await axios.put(secure_url_response.upload_url, file, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            setProgress(progress)
                        }
                    },
                    signal: controller.current !== null ? controller.current.signal : undefined
                })

                if (upload_response.status === 200) {
                    const wktBBox = bbox && latLngBoundsToWKT(bbox)
                    uploadImageInfoTrigger({ name: file.name, object_key: secure_url_response.object_key, bbox: wktBBox })
                    setLoading(false)
                    setProgress(0)
                    setReady(true)
                }
                controller.current = null
                setAlert({ severity: 'success', message: 'Imagen subida correctamente', alertOpen: true })
            }
            catch (err) {
                setProgress(0)
                setLoading(false)
                controller.current = null
                if (axios.isCancel(err)) {
                    setAlert({ severity: 'info', message: 'Subida cancelada', alertOpen: true })
                }
                else {
                    setAlert({ severity: 'error', message: 'Error al subir la imagen', alertOpen: true })
                }
            }
        }
    }

    const handleCancel = useCallback(() => {
        if (validFile) {
            setFile(null)
            setBbox(undefined)
            setValidFile(false)
            setReady(false)
        }
        else {
            onClose && onClose()
        }
    }, [validFile, setFile, setBbox, setValidFile, onClose])

    useEffect(() => {
        setExternalBbox && setExternalBbox(bbox)
    }, [bbox, setExternalBbox])

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 1,
            height: 1,
            alignItems: 'center',
        }}
    >
        {!validFile &&
            <Box
                sx={{
                    height: 1,
                    width: 1,
                    cursor: 'default',
                    borderRadius: 4,
                }}
                onDrop={(event) => {
                    event.preventDefault()
                    setDropping(false)
                    fileUpload(event.dataTransfer.files[0])
                }}
                onDragOver={(e) => {
                    e.preventDefault()
                    setDropping(true)
                }}
                onDragLeave={(e) => {
                    e.preventDefault()
                    setDropping(false)
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        padding: 1,
                        height: 1,
                        width: 1,
                        borderRadius: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dashed',
                            borderWidth: 2,
                            borderColor: dropping ? theme.palette.primary.main : 'gray',
                            backgroundColor: !dropping ? theme.palette.background.default : theme.palette.background.paper,
                            borderRadius: 4,
                            boxSizing: 'border-box',
                            width: 1,
                            height: 1,
                        }}
                    >

                        <Icon sx={{ fontSize: '100px', padding: 4 }} color={dropping ? "primary" : "disabled"}>
                            <SaveAlt color="inherit" fontSize="inherit" />
                        </Icon>
                        <Typography variant="h5">Suelta el archivo aquí</Typography>
                        <Button component="label" role={undefined} tabIndex={-1} sx={{ marginBottom: 2 }} variant="contained">
                            O buscar archivo
                            <VisuallyHiddenInput type="file" onChange={(event) => event.target.files && fileUpload(event.target.files[0])} />
                        </Button>
                    </Box>
                </Box>
            </Box>
        }
        {file && !loading &&
            <Grid2 container spacing={2} sx={{ paddingTop: 2 }}>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                        <Typography>File Name: </Typography>
                    </Grid2>
                    <Grid2 size={9}>
                        <Typography>{file.name}</Typography>
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                        <Typography>File size: </Typography>
                    </Grid2>
                    <Grid2 size={9}>
                        <Typography>{formatBytes(file.size)}</Typography>
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                        <Typography>File type: </Typography>
                    </Grid2>
                    <Grid2 size={9}>
                        <Typography>{file.type}</Typography>
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    <Divider />
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                        <Typography>Coordenadas</Typography>
                    </Grid2>
                    <Grid2 size={2}>
                        <Typography>Suroeste</Typography>
                    </Grid2>
                    <Grid2 size={2}>
                        <Typography>Lat:</Typography>
                    </Grid2>
                    <Grid2 size={5}>
                        {bbox && <Typography>{`${bbox.getSouthWest().lat}`}</Typography>}
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                    </Grid2>
                    <Grid2 size={2}>
                    </Grid2>
                    <Grid2 size={7}>
                        <Divider />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                    </Grid2>
                    <Grid2 size={2}>
                    </Grid2>
                    <Grid2 size={2}>
                        <Typography>Lng:</Typography>
                    </Grid2>
                    <Grid2 size={5}>
                        {bbox && <Typography>{`${bbox.getSouthWest().lng}`}</Typography>}
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                    </Grid2>
                    <Grid2 size={9}>
                        <Divider />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                    </Grid2>
                    <Grid2 size={2}>
                        <Typography>Noreste</Typography>
                    </Grid2>
                    <Grid2 size={2}>
                        <Typography>Lat</Typography>
                    </Grid2>
                    <Grid2 size={5}>
                        {bbox && <Typography>{`${bbox.getNorthEast().lat}`}</Typography>}
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                    </Grid2>
                    <Grid2 size={2}>
                    </Grid2>
                    <Grid2 size={7}>
                        <Divider />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3}>
                    <Grid2 size={3}>
                    </Grid2>
                    <Grid2 size={2}>
                    </Grid2>
                    <Grid2 size={2}>
                        <Typography>Lng</Typography>
                    </Grid2>
                    <Grid2 size={5}>
                        {bbox && <Typography>{`${bbox.getNorthEast().lng}`}</Typography>}
                    </Grid2>
                </Grid2>
            </Grid2>
        }
        {loading &&
            <Stack sx={{ width: 1 }} direction={'column'} spacing={1}>
                <Typography>Subiendo</Typography>
                <Paper sx={{ width: 1, margin: 2, padding: 1, boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 1, boxSizing: 'border-box' }}>
                        <Icon sx={{ paddingX: 2 }}>
                            <UploadFile />
                        </Icon>
                        <LinearProgress variant="determinate" value={progress} sx={{ flexGrow: 1, height: 10, borderRadius: 2 }} />
                        <Typography variant="body2" paddingX={2}>{progress}%</Typography>
                        <Tooltip title="Cancelar subida">
                            <IconButton size="medium" onClick={() => controller.current && controller.current.abort()}>
                                <Cancel fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Paper>
            </Stack>
        }
        <Box sx={{ marginTop: 2 }} >
            <CustomAlertDialog alertOpen={alertOpen} setAlertOpen={(open) => setAlert({ severity, message, alertOpen: open })} message={message} severity={severity} />
        </Box>
        {!loading && validFile &&
            <Stack direction="row" spacing={3} marginY={2}>
                <Button
                    color="error"
                    onClick={handleCancel}
                >
                    Descartar
                </Button>
                {ready
                    ?
                    <Button variant="contained" color="success" onClick={() => onClose && onClose()}>
                        Listo
                    </Button>
                    : <LoadingButton disabled={!validFile} variant="contained" onClick={handleUpload}>Subir</LoadingButton>
                }
            </Stack>
        }
    </Box>
}

export default UploadFileModal

import { Handle, Position, useNodeId, useStore } from "@xyflow/react";
import { ModelHandleProps } from "../types/NodesTypes";

const ModelHandle = ({ type, props }: { type: 'source' | 'target', props: ModelHandleProps }) => {
    const nodeId = useNodeId()
    const isConnected = useStore(s => s.edges.some(edge => (edge.source === nodeId && edge.sourceHandle === props.id) || (edge.target === nodeId && edge.targetHandle === props.id)));
    return <Handle
        type={type}
        position={type === "target" ? Position.Left : Position.Right}
        id={props.id}
        style={{
            height: '25px',
            width: '25px',
            position: 'relative',
            borderStyle: 'none',
            borderRadius: 0,
            backgroundColor: isConnected ? 'blue' : ''
        }}
    />
}

export default ModelHandle
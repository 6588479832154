import { Avatar, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid2, Link, Stack, Tab, TextField, Typography, useTheme } from "@mui/material";
import { useGetCurrentUserInfoQuery, useGetOrgCreditsQuery } from "../api/authApi";
import { User } from "../types/UserTypes";
import { dateFormat } from "../../common/components/UI/general/InfoDisplay";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { Code, CodeOff, Home } from "@mui/icons-material";


const ProfilePage = ({ userData, creditsData }: { userData: User, creditsData: { credits: number } }) => {
    const theme = useTheme()
    const [tab, setTab] = useState('settings')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    }

    return <Box sx={{ width: 1, minHeight: 1, paddingY: 2, boxSizing: "border-box", overflow: 'auto' }}>
        <Stack spacing={3} paddingX={5}>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                >
                    <Home sx={{ mr: 0.5 }} fontSize="inherit" />
                </Link>
                <Typography color="textPrimary">Usuarios</Typography>
                <Typography color="textPrimary">Perfil</Typography>
            </Breadcrumbs>
            <Typography variant="h4">Perfil</Typography>
        </Stack>
        <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} sx={{ paddingX: 3 }}>
                    <Tab label="Configuraciones" value="settings" />
                    <Tab label="Organización" value="team" />
                </TabList>
            </Box>
            <TabPanel value="settings">
                <Container maxWidth={"md"}>
                    <Stack direction={'column'} spacing={5}>
                        <Box sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 2, border: 1, borderColor: theme.palette.divider }}>
                            <Stack direction='column' divider={<Divider flexItem />}>
                                <Stack direction={'column'} padding={2}>
                                    <Typography variant="h5">Información Personal</Typography>
                                    <Typography variant="subtitle1" color="gray">Edita tu información personal</Typography>
                                </Stack>
                                <Stack direction={'row'} spacing={3} sx={{ width: 1 }} padding={3}>
                                    <Avatar sx={{ width: 150, height: 150 }}>
                                        {userData.display_name[0]}
                                    </Avatar>
                                    <Stack spacing={1} direction={'column'} flexGrow={1}>
                                        <Typography>Nombre</Typography>
                                        <TextField size="small" fullWidth value={userData.display_name} />
                                        <Typography>Nombre de usuario</Typography>
                                        <TextField size="small" fullWidth value={userData.username} />
                                        <Typography>Email</Typography>
                                        <TextField size="small" fullWidth value={userData.email} />
                                    </Stack>
                                </Stack>
                                <Stack direction={'row-reverse'} spacing={3} sx={{ width: 1 }} padding={2}>
                                    <Button variant="contained" color="primary">
                                        Guardar
                                    </Button>
                                    <Button variant="outlined" color="inherit">
                                        Cancelar
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box sx={{ backgroundColor: theme.palette.background.paper, borderRadius: 2, border: 1, borderColor: theme.palette.divider }}>
                            <Stack direction='column' divider={<Divider flexItem />}>
                                <Stack direction={'column'} padding={2}>
                                    <Typography variant="h5">Información de la cuenta</Typography>
                                    <Typography variant="subtitle1" color="gray">Datos de conexión y créditos</Typography>
                                </Stack>
                                <Stack spacing={1} direction={'column'} flexGrow={1} paddingY={1} paddingX={3}>
                                    <Grid2 container spacing={1} alignItems={'center'}>
                                        <Grid2 size={4}>
                                            <Typography>Créditos</Typography>
                                        </Grid2>
                                        <Grid2 size={8}>
                                            <TextField size="small" fullWidth value={`${(creditsData.credits).toFixed(2)}`} />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Typography>Fecha de creación</Typography>
                                        </Grid2>
                                        <Grid2 size={8}>
                                            <TextField size="small" fullWidth value={dateFormat(userData.created_at)} />
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Typography>Última vez ingresado</Typography>
                                        </Grid2>
                                        <Grid2 size={8}>
                                            <TextField size="small" fullWidth value={dateFormat(userData.logged_at)} />
                                        </Grid2>
                                    </Grid2>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </TabPanel>
            <TabPanel value="team" >
                <Box sx={{height: 1, width: 1}}>
                    <Stack alignItems={'center'} justifyContent={'center'} sx={{height: '70vh'}}>
                        <CodeOff color="disabled" sx={{fontSize: 100}} />
                        <Typography variant="h3">En desarrollo</Typography>
                    </Stack>
                </Box>
            </TabPanel>
        </TabContext>
    </Box>

    /*
    <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
        <Container>
            <Typography variant="h4" margin={5} textAlign='center'>Perfil</Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
                <Grid item xs={4}>
                    <Typography>Nombre</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth value={userData.display_name} sx={{ marginY: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Nombre de usuario</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth value={userData.username} sx={{ marginY: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Email</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth value={userData.email} sx={{ marginY: 5 }} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Última vez ingresado</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography marginY={5}>{dateFormat(userData.logged_at)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
    
                <Grid item xs={4}>
                    <Typography>Usuario creado</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography marginY={5}>{dateFormat(userData.created_at)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
    
                <Grid item xs={4}>
                    <Typography>Créditos disponibles</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography marginY={5}>{`${(creditsData.credits).toFixed(2)}`}</Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>
    */
}


const Profile = () => {
    const { data: userData, isSuccess: userIsSuccess } = useGetCurrentUserInfoQuery();
    const { data: creditsData, isSuccess: creditsIsSuccess } = useGetOrgCreditsQuery();

    return (
        <Box sx={{ height: 1, width: 1, overflow: 'auto' }}>
            {userIsSuccess && creditsIsSuccess
                ? <ProfilePage userData={userData} creditsData={creditsData} />
                : <Box sx={{ height: 1, width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box>
            }
        </Box>
    );
}

export default Profile;

import { Edge } from "@xyflow/react";
import { getStepFunctionStateInfo, getStepFunctionStateNames } from "../../process/utils/stepFunctionUtils";
import { DataBaseNodes, DataNodes, FlowNodes, OperationNodes } from "../types/NodesTypes";
import { PipelineDefinition, PipelineNode } from "../types/PipelineGraphTypes";
import { SFExecutionEvent, SFStateDisplay, SFStateMachineDefinition } from "../types/StepFunctionTypes";
import { Theme } from "@mui/material";

function getNodeType(node: PipelineNode) {
    switch (node.type) {
        case ('database'):
            return DataBaseNodes[node.value as keyof typeof DataBaseNodes]
        case ('data'):
            return DataNodes[node.value as keyof typeof DataNodes]
        case ('operation'):
            return OperationNodes[node.value as keyof typeof OperationNodes]
        case ('flow'):
            return FlowNodes[node.value as keyof typeof FlowNodes]
        default:
            return null;
    }
}

function getEdgeColor(theme: Theme, node?: any) {
    if (node === undefined) return ''

    switch (node.data.status) {
        case 'finished':
            return theme.palette.success.main
        case 'running':
            return theme.palette.info.main
        case 'canceled':
            return theme.palette.warning.main
        case 'failed':
            return theme.palette.error.main
        default:
            return theme.palette.divider
    }
}

export function getPipelineModelGraph(definition: PipelineDefinition) {
    const nodes = definition.nodes.map((node) => {
        const data = getNodeType(node);
        return {
            id: node.id,
            data: data,
            type: node.type,
            width: 250,
            height: 200,
        }
    });

    const edges: Edge[] = definition.edges.map((edge) => {
        return {
            id: `xy_edge__${edge.source}${edge.source_output}-${edge.target}${edge.target_input}`,
            source: edge.source,
            target: edge.target,
            sourceHandle: edge.source_output,
            targetHandle: edge.target_input,
            style: {
                strokeWidth: 3,
            },
        }
    });

    return { nodes, edges };
}

export function getPipelineGraph(definition: PipelineDefinition, theme: Theme, stateMachineDef?: SFStateMachineDefinition, history?: SFExecutionEvent[]) {
    let states_info: { [key: string]: SFStateDisplay } = {};
    if (stateMachineDef && history) {
        const state_names = getStepFunctionStateNames(stateMachineDef)
        states_info = getStepFunctionStateInfo(state_names, history);
    }

    const nodes = definition.nodes.map((node) => {
        const data = getNodeType(node);
        return {
            id: node.id,
            data: {
                ...data,
                status: states_info[node.id]?.status
            },
            type: node.type,
            width: 250,
            height: 200,
        }
    });

    const edges: Edge[] = definition.edges.map((edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.source)

        return {
            id: `xy_edge__${edge.source}${edge.source_output}-${edge.target}${edge.target_input}`,
            source: edge.source,
            target: edge.target,
            sourceHandle: edge.source_output,
            targetHandle: edge.target_input,
            animated: sourceNode?.data.status === 'running',
            style: {
                strokeWidth: 3,
                stroke: getEdgeColor(theme, sourceNode)
            }
        }
    });

    return { nodes, edges };
}

import ConnectionNode, { ConnectionNodeType } from "../components/ConnectionNode";
import DataNode, { DataNodeType } from "../components/DataNode";
import ModelNode, { ModelNodeType } from "../components/ModelNode";

export const graphNodeTypes = {
    operation: ModelNode,
    database: ModelNode,
    data: DataNode,
    connection: ConnectionNode,
    flow: ModelNode,
};

export type RovNodeType = ModelNodeType | DataNodeType | ConnectionNodeType

export function isModelNode(node: RovNodeType): node is ModelNodeType {
    return node.type === 'Model';
}

export function isDataNode(node: RovNodeType): node is DataNodeType {
    return node.type === 'Data';
}

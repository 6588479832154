import React from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import LogOut from '../../../authentication/views/Logout';
import { useSelector } from 'react-redux';
import { Button, useTheme } from '@mui/material';
import { RootState } from '../../state/store';
import {
    filterUserPages,
    privatePages,
    privateSettings,
    publicPages,
    publicSettings,
    roleInitialPaths
} from '../../routes/routes';
import { useGetOrgCreditsQuery } from '../../../authentication/api/authApi';
import { DarkMode, LightMode } from '@mui/icons-material';

function OrgCredits() {
    const { data } = useGetOrgCreditsQuery();

    return (data
        ? <Box paddingRight={2}>
            <Typography>{`${data.credits.toFixed(2)} CRED.`}</Typography>
        </Box>
        : null
    )
}


function NavBar({ isDark, setIsDark }: { isDark: boolean, setIsDark: (isDark: boolean) => void }) {
    const { token, username, roles } = useSelector((state: RootState) => state.auth);

    const navigate = useNavigate()
    const theme = useTheme()

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const filteredPages = filterUserPages(roles, privatePages);

    const defaultPath = roleInitialPaths[roles[0] as keyof typeof roleInitialPaths]

    return (
        <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} color='default'>
            <Box sx={{ paddingX: 2 }}>
                <Toolbar disableGutters>
                    <Button
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {
                            navigate(defaultPath);
                        }}
                        sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                        color='primary'
                    >
                        <Box
                            component="img"
                            height="50px"
                            src={theme.palette.mode === 'light'
                                ? 'https://rovisen-public-assets.s3.us-east-2.amazonaws.com/logos/rovisen/Logotippo+Original.png'
                                : 'https://rovisen-public-assets.s3.us-east-2.amazonaws.com/logos/rovisen/Logotipo+letras+blancas.png'
                            } />
                    </Button>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {token && filteredPages.map((page) => (
                                <MenuItem key={page.name} onClick={() => {
                                    handleCloseNavMenu();
                                    navigate(page.path);
                                }}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                            {!token && publicPages.map((page) => (
                                <MenuItem key={page.name} onClick={() => {
                                    handleCloseNavMenu();
                                    navigate(page.path);
                                }}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} flexGrow={1} justifyContent={'center'}>
                        <Button
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => {
                                navigate(defaultPath);
                            }}
                        >
                            <Box
                                component="img"
                                height="50px"
                                src={theme.palette.mode === 'light'
                                    ? 'https://rovisen-public-assets.s3.us-east-2.amazonaws.com/logos/rovisen/Logotippo+Original.png'
                                    : 'https://rovisen-public-assets.s3.us-east-2.amazonaws.com/logos/rovisen/Logotipo+letras+blancas.png'
                                }
                            />
                        </Button>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {token && filteredPages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => {
                                    handleCloseNavMenu();
                                    navigate(page.path);
                                }}
                            >
                                <Typography color={theme.palette.text.primary} textAlign="center">{page.name}</Typography>
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" }}}>
                        {username &&
                            <OrgCredits />
                        }
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Menú">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar>{username ? username[0].toUpperCase() : "R"}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {token && privateSettings.map((setting) => (
                                <MenuItem key={setting.name} onClick={() => {
                                    handleCloseUserMenu();
                                    navigate(setting.path);
                                }}>
                                    <Typography textAlign="center">{setting.name}</Typography>
                                </MenuItem>
                            ))}
                            {token && <LogOut />}
                            {!token && publicSettings.map((setting) => (
                                <MenuItem key={setting.name} onClick={() => {
                                    handleCloseUserMenu();
                                    navigate(setting.path);
                                }}>
                                    <Typography textAlign="center">{setting.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ paddingX: 2 }}>
                        <IconButton onClick={() => setIsDark(!isDark)}>
                            {isDark ? <LightMode /> : <DarkMode />}
                        </IconButton>
                    </Box>
                </Toolbar>
            </Box>
        </AppBar>
    );
}

export default NavBar;

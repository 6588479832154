import { Box, Breadcrumbs, Button, Chip, CircularProgress, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { PipelineModel, statusEnum } from "../../types/DataTypes"
import BaseMap from "../../../common/components/map/BaseMap"
import { useGetPipelineInstanceQuery, useGetPipelineModelQuery } from "../../api/processApi"
import ExecutionDetailsData from "./ExecutionDetailsData"
import ExecutionDetailsMap from "./ExecutionDetailsMap"
import { ArrowDropDown, ContentCopy, Delete, ExpandLess, ExpandMore, Refresh } from "@mui/icons-material"
import DetailItem from "../common/DetailItem"
import { Pane } from "react-leaflet"
import { PipelineInstanceGraph } from "../../../pipeline/views/GraphView"
import CenteredCircularProgress from "../../../common/components/UI/general/CenteredCircularProgress"
import { SecondsToStopWatch } from "../../../common/components/UI/general/InfoDisplay"
import { pipelineInstancePriceDisplay } from "../../utils/utils"
import NotFound from "../../../app/views/NotFound"
import { useGetUsersQuery } from "../../../authentication/api/authApi"
import DateDisplay from "../common/DateDisplay"

const ResultsButton = ({ status }: { status: string | undefined }) => {
    const navigate = useNavigate()
    return <Button variant="outlined" disabled={status !== "finished"} onClick={() => navigate(`results`)}>Ver resultados</Button>
}

const Details = ({ model, instanceId }: { model: PipelineModel, instanceId: string }) => {
    const { currentData: instance, isFetching, isError, refetch } = useGetPipelineInstanceQuery(instanceId, { refetchOnMountOrArgChange: true })
    const [previsualization, setPrevisualization] = useState<string[] | null>(["input", "output"])
    const status = statusEnum[instance?.status || 'queued']
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const navigate = useNavigate()
    const { user } = useGetUsersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            user: data?.find(user => user.id === instance?.user_id),
        })
    })

    const [iPanel, setIPanel] = useState<boolean>(true)
    const [oPanel, setOPanel] = useState<boolean>(true)

    useEffect(() => {
        if (isError) {
            navigate('/404')
        }
    }, [isError, navigate])

    return <Box sx={{ width: 1, minHeight: 1, paddingX: 5, paddingY: 2, boxSizing: "border-box", overflow: 'auto', backgroundColor: theme.palette.background.default, }}>
        <Stack spacing={3}>
            <Breadcrumbs aria-label="breadcrumb" separator=">">
                <Link to="/processes">Procesos</Link>
                <Link to={`/processes/${model.id}`}>{model.subtitle ? `${model.title}:${model.subtitle}` : model.title}</Link>
                <Typography color="textPrimary">{`Ejecución: ${instanceId}`}</Typography>
            </Breadcrumbs>
            <Stack
                direction={{ lg: 'column', xl: 'row' }}
                alignItems={{ lg: 'flex-start', xl: 'center' }}
                spacing={{ lg: 3, xl: 2 }}
                justifyContent={{ lg: "center", xl: "space-between" }}
            >
                <Stack spacing={0} flexGrow={1}>
                    <Typography variant="h4">{`Ejecución: ${instance?.name}`}</Typography>
                    <Typography variant="subtitle1" color='gray'>{`ID: ${instanceId}`}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Button variant="outlined" onClick={() => refetch()} disabled={instance?.status === "finished"}>
                        <Refresh />
                    </Button>
                    <Button variant="outlined" endIcon={<ArrowDropDown />} onClick={(e) => setAnchorEl(e.currentTarget)}>Acciones</Button>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <List dense>
                            <ListItem disablePadding>
                                <ListItemButton disabled>
                                    <ListItemIcon>
                                        <Delete />
                                    </ListItemIcon>
                                    <ListItemText primary="Borrar" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate(`/processes/${model.id}/new_execution?copyFrom=${instanceId}`)}>
                                    <ListItemIcon>
                                        <ContentCopy />
                                    </ListItemIcon>
                                    <ListItemText primary="Duplicar" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Popover>
                    <ResultsButton status={instance?.status} />
                </Stack>
            </Stack>
            <Paper sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <Stack
                    direction={{ md: 'column', lg: 'row' }}
                    sx={{ width: 1, padding: 2, boxSizing: "border-box" }}
                    spacing={{ md: 0, lg: 2 }}
                    divider={<Divider variant="middle" orientation="vertical" flexItem />}
                >
                    <Box sx={{ width: 1 }}>
                        <DetailItem label="Estado">
                            {isFetching ? <CircularProgress size={20} /> : <Chip label={status.name} color={status.color} icon={status.icon}/>}
                        </DetailItem>
                        <DetailItem label="Modelo">
                            {isFetching ? <CircularProgress size={15} /> : <Typography>{model.subtitle ? `${model.title}:${model.subtitle}` : model.title}</Typography>}
                        </DetailItem>
                        <DetailItem label="Fecha de creación">
                            {isFetching ? <CircularProgress size={15} /> : instance && <DateDisplay date={instance.created_at} />}
                        </DetailItem>
                        <DetailItem label="Creado por">
                            {isFetching ? <CircularProgress size={15} /> : <Typography>{user?.display_name}</Typography>}
                        </DetailItem>
                    </Box>
                    <Box sx={{ width: 1 }}>
                        <DetailItem label="Fecha de inicio de la ejecución">
                            {isFetching ? <CircularProgress size={15} /> : instance?.started_at ? <DateDisplay date={instance.started_at} /> : <Typography>-</Typography>}
                        </DetailItem>
                        <DetailItem label="Fecha de finalización de la ejecución">
                            {isFetching ? <CircularProgress size={15} /> : instance?.finished_at ? <DateDisplay date={instance.finished_at} /> : <Typography>-</Typography>}
                        </DetailItem>
                        <DetailItem label="Tiempo de ejecución">
                            {isFetching ? <CircularProgress size={15} /> : <Typography>{instance?.runtime ? SecondsToStopWatch(instance.runtime) : "-"}</Typography>}
                        </DetailItem>
                        <DetailItem label="Costo (CRÉDITOS)">
                            {isFetching
                                ? <CircularProgress size={15} />
                                : <Typography>{instance ? pipelineInstancePriceDisplay(instance) : '-'}</Typography>
                            }
                        </DetailItem>
                    </Box>
                </Stack>
            </Paper>
            <Paper>
                <Stack
                    direction={{ md: 'column', lg: 'row' }}
                    sx={{ width: 1, padding: 2, boxSizing: "border-box" }}
                    spacing={{ md: 1, lg: 2 }}
                    divider={<Divider variant="middle" orientation="vertical" flexItem />}
                >
                    <Box sx={{ width: 1 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h6">Entradas</Typography>
                            <IconButton onClick={() => setIPanel(!iPanel)}>
                                {iPanel ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Stack>
                        <Collapse in={iPanel}>
                            {instance && <ExecutionDetailsData format={model.input_format} data={instance.input_data} />}
                        </Collapse>
                    </Box>
                    <Box sx={{ width: 1 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h6">Salidas</Typography>
                            <IconButton onClick={() => setOPanel(!oPanel)}>
                                {oPanel ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Stack>
                        <Collapse in={oPanel}>
                            {instance && <ExecutionDetailsData format={model.output_format} data={instance.output_data} isLoading={isFetching} />}
                        </Collapse>
                    </Box>
                </Stack>
            </Paper>
            <Stack
                direction={{ lg: 'column', xl: 'row' }}
                sx={{ width: 1, boxSizing: "border-box" }}
                spacing={{ xs: 3, lg: 3, xl: 2 }}
            >
                <Paper sx={{ display: "flex", flexDirection: "column", height: 500, borderRadius: 2, overflow: "hidden", width: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={2} padding={1}>
                        <Typography variant="h5">Previsualización</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={previsualization}
                                size="small"
                                onChange={(e, value) => setPrevisualization(value)}
                            >
                                <ToggleButton value="input">Entrada</ToggleButton>
                                <ToggleButton value="output">Salida</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <ResultsButton status={instance?.status} />
                    </Stack>
                    <Box sx={{ flexGrow: 1 }}>
                        <BaseMap baseMap="osm" trackResize>
                            <Pane name="execution_details_input" style={{ zIndex: 500 }} />
                            <Pane name="execution_details_output" style={{ zIndex: 500 }} />
                            {instance && previsualization?.includes("input") && <ExecutionDetailsMap format={model.input_format} data={instance.input_data} pane="execution_details_input" />}
                            {instance && previsualization?.includes("output") && <ExecutionDetailsMap format={model.output_format} data={instance.output_data} pane="execution_details_output" />}
                        </BaseMap>
                    </Box>
                </Paper>
                <Paper sx={{ height: 500, width: 1 }}>
                    {(!isFetching && instance) ? <PipelineInstanceGraph modelId={model.id} instanceId={instanceId} /> : <CenteredCircularProgress />}
                </Paper>
            </Stack>
        </Stack>
    </Box>
}

const ExecutionConfigGetter = ({ modelId, instanceId }: { modelId: string, instanceId: string }) => {
    const { data, isLoading, isSuccess } = useGetPipelineModelQuery(modelId)
    return isLoading
        ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 1, width: 1 }}>
            <CircularProgress />
        </Box>
        : isSuccess ? <Details model={data} instanceId={instanceId} /> : <NotFound />
}

const ExecutionDetails = () => {
    const { modelId, instanceId } = useParams()

    return modelId && instanceId ? <ExecutionConfigGetter modelId={modelId} instanceId={instanceId} /> : <NotFound />
}

export default ExecutionDetails
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import ModelHandle from "./ModelHandle";
import { ModelNodeProps } from "../types/NodesTypes";
import { StatusDisplay } from "../../common/components/UI/general/InfoDisplay";
import { Node, NodeProps } from "@xyflow/react";
import { memo } from "react";

export const NodeColor = {
    "export": "#fff07c",
    "data": "#80ff72",
    "tool": "#3dacbf",
    "util": "#ab87ff",
    "model": "#F05D5E",
    "flow": "#ab87ff",
}

export type ModelNodeType = Node<ModelNodeProps, 'Model'>
const ModelNode = memo(({ data, selected }: NodeProps<ModelNodeType>) => {
    const theme = useTheme()
    
    if (data === undefined) return null

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.paper,
                overflow: 'hidden',
            }}
            elevation={selected ? 5 : 1}
        >
            <Box sx={{ background: NodeColor[data.type], padding: 1 }}>
                <Typography variant="h6" color='white'>{data.name}</Typography>
                {data.status && <StatusDisplay status={data.status} />}
            </Box>
            <Stack spacing={2} direction="row" padding={2}>
                <Stack spacing={1} flexGrow={1}>
                    {data.inputs.map(input => <Stack spacing={1} direction={'row'} alignItems={'center'} key={input.id}>
                        <ModelHandle type="target" props={input} />
                        <Typography>{input.name}</Typography>
                    </Stack>)}
                </Stack>
                <Stack spacing={1} flexGrow={1}>
                    {data.outputs.map(output => <Stack spacing={1} direction='row-reverse' alignItems='center' key={output.id}>
                        <ModelHandle type="source" props={output} />
                        <Typography>{output.name}</Typography>
                    </Stack>)}
                </Stack>
            </Stack>
        </Paper>
    );
})

export default ModelNode
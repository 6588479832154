import { Download } from "@mui/icons-material";
import { Box, IconButton, Slider, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { ToggleVisibility } from "../../../common/components/UI/general/ToggleVisbility";
import { useGetRasterQuery } from "../../api/processApi";
import { wktToBounds } from "../../../common/utils/tools";
import { ImageOverlay } from "react-leaflet";


export function DateRangeDisplay({ init, end }: {init: string | undefined, end: string | undefined}) {

    return <>
            <Typography flexGrow={1}>Fechas de comparación</Typography>
            <TextField
                InputProps={{
                    readOnly: true,
                }}
                label={"Fecha de inicio"}
                value={init}
                focused
                />
            <TextField
                InputProps={{
                    readOnly: true,
                }}
                label={"Fecha de término"}
                value={end}
                focused
            />
        </>
}

export function GeomDisplay({ showMask, setShowMask }: { showMask: boolean, setShowMask: React.Dispatch<React.SetStateAction<boolean>> }) {
    return <>
        <Typography flexGrow={1}>Área de análisis</Typography>
        <ToggleVisibility tooltip={'detecciones'} show={showMask} setShow={setShowMask} />
    </>
}

export function RasterDisplay({ name, showRaster, setShowRaster }: { name: string | undefined, showRaster: boolean, setShowRaster: React.Dispatch<React.SetStateAction<boolean>> }) {
    return <>
        <Stack flexGrow={1} spacing={1}>
            <Typography>Raster:</Typography>
            <Typography>{name}</Typography>
        </Stack>
        <ToggleVisibility tooltip={'detecciones'} show={showRaster} setShow={setShowRaster} />
    </>
}

export function RasterOpacityDisplay({title, showRaster, setShowRaster, opacity, setOpacity}: {title: string, showRaster: boolean, setShowRaster: React.Dispatch<React.SetStateAction<boolean>>, opacity: number, setOpacity: React.Dispatch<React.SetStateAction<number>>}) {
    return <>
        <Stack flexGrow={1}>
            <Typography >{title}</Typography>
            <Typography color={'gray'}>Opacidad</Typography>
            <Slider
                valueLabelDisplay="auto"
                step={0.05}
                value={opacity}
                min={0}
                max={1}
                onChange={(_, value) => setOpacity(value as number)}
            />
        </Stack>
        <ToggleVisibility tooltip={'detecciones'} show={showRaster} setShow={setShowRaster} />
    </>
}

export function RasterMapDisplay({rasterId, opacity}: {rasterId: string, opacity: number}) {
    const { data: raster } = useGetRasterQuery(rasterId)

    return raster && raster.preview ? <ImageOverlay url={raster.preview} bounds={wktToBounds(raster.preview_bbox)} opacity={opacity} /> : null
}

export function DetectionsDisplay({show, setShow, title= 'Detecciones', onClick = () => {}}: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, title?: string, onClick?: () => void}) {
    return <>
        <Typography flexGrow={1}>{title}</Typography>
        <Tooltip title={`Descargar geojson`}>
            <IconButton size="medium" onClick={onClick}>
                <Download fontSize="inherit" />
            </IconButton>
        </Tooltip>
        <ToggleVisibility tooltip={'detecciones'} show={show} setShow={setShow} />
    </>
}

export function DetectionOptionsDisplay({detections, setDetections, show, setShow, onClick = () => {}, }: {detections: string, setDetections: React.Dispatch<React.SetStateAction<string>>, show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, onClick?: () => void}) {
    return <Stack flexGrow={1}>
        <Box display={'flex'}>
            <DetectionsDisplay show={show} setShow={setShow} onClick={onClick} />
        </Box>
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography flexGrow={1} color={'gray'}>Umbral de probabilidad de deteccion:</Typography>
            <ToggleButtonGroup
                value={detections}
                onChange={(e, value) => {
                    setDetections(value)
                }}
                exclusive
            >
                <Tooltip title={detections === "0.10" ? "No mostrar detecciones" : "Mostrar detecciones con umbral 0.10"}>
                    <ToggleButton value="0.10">
                        0.10
                    </ToggleButton>
                </Tooltip>
                <Tooltip title={detections === "0.25" ? "No mostrar detecciones" : "Mostrar detecciones con umbral 0.25"}>
                    <ToggleButton value="0.25">
                        0.25
                    </ToggleButton>
                </Tooltip>
                <Tooltip title={detections === "0.50" ? "No mostrar detecciones" : "Mostrar detecciones con umbral 0.50"}>
                    <ToggleButton value="0.50">
                        0.50
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </Stack>
    </Stack>
}

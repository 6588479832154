import { AddRoad, Close, Download, Preview, RemoveRoad, Upload } from "@mui/icons-material"
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import BaseMap from "../../common/components/map/BaseMap"
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { useGetImagesQuery, useLazyGetRasterDownloadLinkQuery, useRecalculateRasterPreviewMutation } from "../api/processApi"
import { LatLng, latLngBounds, LatLngBounds } from "leaflet"
import { ImageOverlay, Rectangle } from "react-leaflet"
import WktLayer from "../../common/utils/WktLayer"
import { latLngBoundsToWKT, wktToBounds, wktToVertexs } from "../../common/utils/tools"
import ResizeMap from "../../common/components/map/tools/ResizeMap"
import UploadFileModal from "../components/common/UploadFileModal"
import { Raster } from "../types/DataTypes"
import DraggableLayout from "../../common/components/layout/DraggableLayout"
import { LoadingButton } from "@mui/lab"
import BaseMapControls, { ControlGroup } from "../../common/components/map/layout/BaseMapControls"
import LocationUtilities from "../../common/components/map/control/LocationUtilities"
import BaseMapSelector from "../../mainMap/components/control/BaseMapSelector"
import ToggleButton from "../../common/components/UI/general/ToggleButton"
import { setStreets } from "../../mainMap/state/baseMapSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/state/store"
import { MapLibreTileLayer } from "../../common/utils/MapLibreTileLayer"
import { dateFormat } from "../../common/components/UI/general/InfoDisplay"
import DrawingUtilities from "../../common/components/map/control/DrawingUtilities"

const RasterActions = ({ id, params }: { id: string, params: GridRenderCellParams<Raster> }) => {
    const [getRasterLink, rasterLink] = useLazyGetRasterDownloadLinkQuery()
    const [open, setOpen] = useState(false)
    const [recalculate, result] = useRecalculateRasterPreviewMutation()
    const [scale, setScale] = useState(0.5)
    const [type, setType] = useState('rgb')

    useEffect(() => {
        if (rasterLink.isSuccess) {
            window.location.href = rasterLink.data
        }
    }, [rasterLink])

    useEffect(() => {
        result.isSuccess && setOpen(false)
    }, [result])

    return <Stack direction={'row'} spacing={1} alignItems={'center'} height={1}>
        <Tooltip title="Descargar">
            <IconButton onClick={() => getRasterLink(id)}>
                <Download />
            </IconButton    >
        </Tooltip>
        <Tooltip title="Recalcular vista previa">
            <IconButton onClick={() => {
                setOpen(true)
                params.api.selectRow(params.id, true, true)
            }}>
                <Preview />
            </IconButton>
        </Tooltip>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Recalcular vista previa</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Esta imagen se utiliza para mostrar una vista previa del raster en el mapa, durante la selección de imágenes o para previsualizar resultados. Puede cambiar la calidad y el tipo de imagen para mejorar la visualización. Este proceso tarda unos minutos.
                </DialogContentText>
                <Stack direction='row' spacing={2} paddingY={2}>
                    <FormControl fullWidth>
                        <InputLabel id="qualityLabel">Calidad</InputLabel>
                        <Select labelId="qualityLabel" label='Calidad' value={scale} onChange={(e) => setScale(Number(e.target.value))}>
                            <MenuItem value={0.1}>Baja</MenuItem>
                            <MenuItem value={0.5}>Media</MenuItem>
                            <MenuItem value={0.9}>Alta</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="label">Tipo</InputLabel>
                        <Select labelId="label" label='Tipo' value={type} onChange={(e) => setType(e.target.value)}>
                            <MenuItem value='gray'>Escala de grises</MenuItem>
                            <MenuItem value='rgb'>RGB</MenuItem>
                            <MenuItem value='bgr'>BGR</MenuItem>
                            <MenuItem value='rgbn'>RGB + NIR</MenuItem>
                            <MenuItem value='bgrn'>BGR + NIR</MenuItem>
                            <MenuItem value='other'>Otro</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <LoadingButton onClick={() => recalculate({ id: id, scale: scale })} loading={result.isLoading}>Aceptar</LoadingButton>
            </DialogActions>
        </Dialog>
    </Stack>
}


const columns: GridColDef<Raster>[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
    },
    {
        field: 'name',
        headerName: 'Nombre',
        width: 200,
    },
    {
        field: 'created_at',
        headerName: 'Agregada a la biblioteca',
        type: 'dateTime',
        valueFormatter: (value, row) => dateFormat(row.created_at),
        width: 200,
    },
    {
        field: 'resolution',
        headerName: 'Resolución',
        width: 100,
    },
    {
        field: 'channels',
        headerName: 'Canales',
        width: 80,
    },
    {
        field: 'dtype',
        headerName: 'Tipo de dato',
        width: 100,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        renderCell: (params) => <RasterActions id={params.row.id} params={params} />,
    }
]

const SendToLibrary = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ padding: 1 }}>No existen imágenes en su biblioteca o en la de su organización.</Typography>
    </Box>
)

const ImageLibrary = () => {
    const [open, setOpen] = useState(false)
    const { data: images, isLoading, isSuccess } = useGetImagesQuery({ origin: 'library' })
    const [bboxsImages, setBboxsImages] = useState<{ bbox?: string, url?: string }[]>([])
    const [bbox, setBbox] = useState<LatLngBounds>()
    const [hoverFootprint, setHoverFootprint] = useState<string>()
    const [currentPosition, setCurrentPosition] = useState<{ center: LatLng, zoom: number }>()
    const [uploadBbox, setUploadBbox] = useState<LatLngBounds>()

    const [openAlert, setOpenAlert] = useState(false)

    const handleRowOver = (e: any) => {
        const rowId = e.currentTarget.dataset.id
        const row = images?.find((el: Raster) => el.id === rowId)
        row && setHoverFootprint(row.preview_bbox)
    }

    useEffect(() => {
        const allBboxs = uploadBbox ? [...bboxsImages, { bbox: latLngBoundsToWKT(uploadBbox), url: "" }] : bboxsImages
        const currentBbox = allBboxs.length > 0 ? latLngBounds(allBboxs.map(b => b.bbox ? wktToVertexs(b.bbox) : []).flat()) : undefined
        setBbox(currentBbox)
    }, [bboxsImages, uploadBbox, setBbox])

    const map = useRef<L.Map>(null)
    const updateMapSize = useCallback(() => {
        map.current && map.current.invalidateSize(true)
    }, [map])

    useEffect(() => {
        bbox && map.current && map.current.fitBounds(bbox)
    }, [bbox, map])

    const baseMapConfig = useSelector((state: RootState) => state.baseMap)
    const dispatch = useDispatch()
    const theme = useTheme()

    return <DraggableLayout
        topLeft={
            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 1,
                overflow: 'hidden'
            }}
                onDragOver={(e) => {
                    e.preventDefault()
                    setOpen(true)
                }}
                onDragLeave={(e) => {
                    e.preventDefault()
                    setOpen(false)
                }}
            >
                <Stack direction={'row'} padding={2}>
                    <Typography flexGrow={1} variant="h4">
                        Mis imágenes
                    </Typography>
                    {open
                        ? <Button variant="contained" color={"secondary"} onClick={() => {
                            setOpen(false)
                            setUploadBbox(undefined)
                        }} endIcon={<Close />}>
                            Cancelar
                        </Button>
                        : <Button variant="contained" color={"primary"} onClick={() => setOpen(true)} endIcon={<Upload />}>
                            Subir imagen
                        </Button>
                    }
                </Stack>
                <Box sx={{ flexGrow: 1, paddingBottom: 1.4, overflow: "hidden" }}>
                    {open
                        ? <UploadFileModal setBbox={setUploadBbox} onClose={() => {
                            setUploadBbox(undefined)
                            setOpen(false)
                        }} />
                        : <Box sx={{ height: 1 }}>
                            <DataGrid
                                rows={isSuccess ? images : []}
                                columns={columns}
                                loading={isLoading}
                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(e, d) => {
                                    const selectedRows = images?.filter((row: any) => e.includes(row.id))
                                    selectedRows && setBboxsImages(selectedRows.map((row: Raster) => ({ bbox: row.preview_bbox, url: row.preview })))
                                    selectedRows && selectedRows.length > 0 && setOpenAlert(true)
                                }}
                                slots={{
                                    noRowsOverlay: SendToLibrary
                                }}
                                slotProps={{
                                    row: {
                                        onMouseEnter: handleRowOver,
                                        onMouseLeave: () => setHoverFootprint(undefined),
                                    }
                                }}
                                sx={{ backgroundColor: theme.palette.background.paper }}
                            />
                        </Box>
                    }
                </Box>
                <Snackbar
                    open={openAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    autoHideDuration={5000}
                    onClose={() => setOpenAlert(false)}
                >
                    <Alert
                        onClose={() => setOpenAlert(false)}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        La imagen mostrada es una previsualización de baja calidad. Puede recalcular la vista previa para mejorar la calidad.
                    </Alert>
                </Snackbar>
            </Container>
        }
        downRight={
            <BaseMap baseMap={baseMapConfig.baseMap} center={currentPosition?.center} zoom={currentPosition?.zoom} innerRef={map}>
                <BaseMapControls>
                    <DrawingUtilities />
                    <LocationUtilities />
                    <ControlGroup>
                        <BaseMapSelector />
                        <Divider orientation='vertical' variant='middle' flexItem />
                        <ToggleButton
                            icon={<AddRoad fontSize='inherit' />}
                            selectedIcon={<RemoveRoad fontSize='inherit' />}
                            tooltip={'Show streets'}
                            selectedTooltip={'HideStreets'}
                            selected={baseMapConfig.streets}
                            enabled={baseMapConfig.baseMap !== 'osm'}
                            onClick={() => dispatch(setStreets(!baseMapConfig.streets))}
                        />
                    </ControlGroup>
                </BaseMapControls>
                {bboxsImages.map((bboxsImage, index) => {
                    if (bboxsImage.bbox && bboxsImage.url) {
                        const bounds = wktToBounds(bboxsImage.bbox)
                        return <>
                            <ImageOverlay bounds={bounds} url={bboxsImage.url} key={index} />
                            <Rectangle bounds={bounds} pathOptions={{ color: 'red', fillOpacity: 0 }} />
                        </>
                    }
                    return null
                })}
                <ResizeMap setPosition={setCurrentPosition} />
                {hoverFootprint && <WktLayer wktData={hoverFootprint} />}
                {uploadBbox && <Rectangle bounds={uploadBbox} pathOptions={{ color: 'green' }} />}
                {baseMapConfig.streets && <MapLibreTileLayer url="https://api.maptiler.com/maps/29b43753-8fe0-4881-b56b-91b28b4f694c/style.json?key=dCFtNpk6lDWiGmtbFNYs" attribution="" />}
            </BaseMap>
        }
        onDragEnd={updateMapSize}
        onChangeOrientation={updateMapSize}
    />
}

export default ImageLibrary
